import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  inject,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslatePipe } from '@codeandweb/ngx-translate';
import { map } from 'rxjs';
import { ClientAuthService } from '../../../services/auth.service';
import { MetaService } from '../../../services/meta.service';
import { JoinNowBtnComponent } from '../../header/join-now-btn/join-now-btn.component';
import { LoginBtnComponent } from '../../header/login-btn/login-btn.component';
import { IconComponent } from '../../icon/icon.component';
import { ServiceProviderPlatformComponent } from '../../service-provider-platform/service-provider-platform.component';
import { SiteInfoComponent } from '../../site-info/site-info.component';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [
    `
      .footer-content {
        font-size: 14px;

        h1 {
          font-size: 36px;
          font-weight: 700;
          color: #3f3d41;
        }

        h2 {
          font-size: 30px;
          font-weight: 500;
          color: #3f3d41;
        }

        h3 {
          font-size: 24px;
          font-weight: 500;
          color: #b8c5d5;
        }

        p {
          font-size: 12px;
          margin-top: 15px;
        }
      }
    `,
  ],
  imports: [
    CommonModule,
    ServiceProviderPlatformComponent,
    TranslatePipe,
    SiteInfoComponent,
    IconComponent,
    LoginBtnComponent,
    JoinNowBtnComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
  metaService = inject(MetaService);
  sanitizer = inject(DomSanitizer);
  router = inject(Router);
  authService = inject(ClientAuthService);

  login = this.authService.isLogin;

  seoContent = toSignal(
    this.metaService.getAllSEOContent().pipe(map((res) => res.data))
  );
  footerContent = toSignal(
    this.metaService.getSetting().pipe(map((res) => res.data?.footerContent))
  );

  footerHtml = computed(() => {
    const content = this.footerContent();
    return content ? this.sanitizer.bypassSecurityTrustHtml(content) : '';
  });

  mobileMenu = signal<{ title: string; page: string; icon: string }[]>([
    { title: 'Home', page: '', icon: '/icons/home.svg' },
    { title: 'Promotion', page: 'promotions', icon: '/icons/promotion.svg' },
    {
      title: 'Depo/WD',
      page: 'account-center/bank/deposits',
      icon: '/icons/money-transfer.svg',
    },
    { title: 'Contact Us', page: 'support', icon: '/icons/contact-us.svg' },
    {
      title: 'My Account',
      page: 'account-center/my-account/account-summary',
      icon: '/icons/my-account.svg',
    },
  ]);
  tabClicked(item: { title: string; page: string }): void {
    const url = [`${item.page}`];
    // open new tab
    window.open(url.join('/'), '_blank');
  }

  goToPage(page: string): void {
    this.router.navigateByUrl(page);
  }
}
