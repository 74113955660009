import { CommonModule } from '@angular/common';
import { Component, effect, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ClientAuthService } from '../../services/auth.service';
import { HomepageService } from '../../services/homepage.service';
import { BrandLogoComponent } from '../brand-logo/brand-logo.component';
import { CategoryComponent } from '../home-page/category/category.component';
import { DownloadAppBannerComponent } from '../home-page/download-app-banner/download-app-banner.component';
import { IconComponent } from '../icon/icon.component';
import { RightDrawerComponent } from '../right-drawer/right-drawer.component';
import { CreditBalanceComponent } from './credit-balance/credit-balance.component';
import { DailyRewardComponent } from './daily-reward/daily-reward.component';
import { JoinNowBtnComponent } from './join-now-btn/join-now-btn.component';
import { LoginBtnComponent } from './login-btn/login-btn.component';
import { PlayerInfoComponent } from './player-info/player-info.component';
import { ToggleLanguageComponent } from './toggle-language/toggle-language.component';

@Component({
  selector: 'app-header',

  imports: [
    CommonModule,
    IconComponent,
    BrandLogoComponent,
    CreditBalanceComponent,
    ToggleLanguageComponent,
    DailyRewardComponent,
    PlayerInfoComponent,
    CategoryComponent,
    LoginBtnComponent,
    RightDrawerComponent,
    JoinNowBtnComponent,
    DownloadAppBannerComponent,
  ],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  router = inject(Router);

  authService = inject(ClientAuthService);
  homepageService = inject(HomepageService);
  isLogin = this.authService.isLogin;

  constructor() {
    effect(() => {
      if (this.isLogin() === true) {
        this.authService.getUserInfo().subscribe();
      }
    });
  }

  selectedCountry = 'England';
  dropdownBankOption = signal<
    {
      label: string;
      value: string | number;
      disable?: boolean;
      path?: string;
    }[]
  >([
    {
      label: 'England',
      value: 'England',
      path: '/icons/france.svg',
    },
    {
      label: 'Indonesia',
      value: 'Indonesia',
      path: '/assets/search.svg',
    },
  ]);
  pathArray = signal<{ value: string | number; path: string }[]>([
    {
      value: 'England',
      path: '/icons/france.svg',
    },
    {
      value: 'Indonesia',
      path: '/assets/search.svg',
    },
  ]);
  dropdownBankValue = signal<{
    label: string;
    value: string | number;
    disable?: boolean;
  }>({
    label: 'England',
    value: '0',
  });

  goToPage(page: string) {
    this.router.navigate([page]);
  }

  logout() {
    this.authService.logout();
  }
}
