import { CommonModule } from '@angular/common';
import { Component, inject, input, model, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslatePipe } from '@codeandweb/ngx-translate';
import { DialogModule } from 'primeng/dialog';
import { map } from 'rxjs';
import { ClientAuthService } from '../../../services/auth.service';
import { HomepageService } from '../../../services/homepage.service';
import { StorageService } from '../../../services/storage.service';
import { ButtonComponent } from '../../button/button.component';
import { IconComponent } from '../../icon/icon.component';
@Component({
  selector: 'login-btn',
  templateUrl: './login-btn.component.html',
  imports: [
    CommonModule,
    ButtonComponent,
    TranslatePipe,
    FormsModule,
    DialogModule,
    IconComponent,
  ],
  styles: [
    `
      :host {
        display: contents;
      }

      .clip-path {
        clip-path: polygon(100% 0, 100% 65%, 89% 100%, 0 100%, 0 0);
      }
    `,
  ],
})
export class LoginBtnComponent {
  type = input<'primary' | 'hexagon' | 'square'>('primary');
  authService = inject(ClientAuthService);
  storageService = inject(StorageService);
  router = inject(Router);
  user = signal({
    userName: '',
    password: '',
  });
  visible = model(false);

  homepageService = inject(HomepageService);

  logo = toSignal(
    this.homepageService.getMetadata().pipe(map((val) => val.merchantLogoImage))
  );

  showLoginDialog() {
    this.visible.set(true);
  }

  login() {
    if (this.user().userName === '' || this.user().password === '') {
      alert('Please fill in the username and password');
      return;
    }
    this.authService
      .login(this.user().userName, this.user().password)
      .subscribe((res) => {
        if (res) {
          this.storageService.setSid(res.data.accessToken);
          this.storageService.setRid(res.data.refreshToken);
          this.storageService.setUid(res.data.userId);
          this.router.navigate(['/']);
        }
      });
  }

  closeDialog() {
    this.visible.set(false);
  }
}
