<div class="bg-global min-h-screen text-white flex flex-col scrollbar w-screen">
  <app-header ngSkipHydration />

  <div class="mx-auto w-full">
    <router-outlet></router-outlet>
  </div>
  <app-footer ngSkipHydration />

  <button
    class="w-[40px] h-[40px] fixed bottom-[70px] right-[10px] 1220:bottom-[20px] 1220:right-[20px] bg-primary grid place-items-center rounded"
    (click)="scrollToTop()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
      class=""
    >
      <g>
        <path
          d="M496.879 360.051c9.967 12.385 11.895 28.987 5.033 43.365-6.863 14.346-21.013 23.268-36.928 23.268H47.016c-15.914 0-30.065-8.921-36.927-23.268-6.863-14.379-4.935-30.98 5.032-43.365l208.984-259.473c7.81-9.706 19.412-15.261 31.862-15.261 12.451 0 24.085 5.556 31.895 15.261z"
          fill="#000000"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  </button>
</div>
