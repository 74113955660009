<p-toast
  styleClass="
[&_.p-toast-message-success]:bg-success
[&_.p-toast-message-text]:text-white
[&_.p-toast-message-error]:bg-danger
[&_.p-toast-message-warning]:bg-warning
[&_.p-toast-message-info]:bg-info
[&_.p-toast-message-content]:p-2
[&_.p-toast-message-icon]:!text-white
[&_.p-toast-icon-close]:!text-white
[&_.p-toast-detail]:!text-white
"
/>
<router-outlet></router-outlet>
