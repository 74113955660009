import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  input,
  model,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'core-control',

  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './control.component.html',
  styleUrl: './control.component.css',
})
export class ControlComponent
  implements ControlValueAccessor, OnDestroy, OnInit
{
  private isDestroyed = false;
  disabled = input<boolean>(false);
  required = input<boolean>(false);
  label = input<string>('');
  helperText = input<string>('');
  placeholder = input<string>('');

  value = model<any>('');

  // for formBuilder
  fieldControl = input<FormControl>();
  fieldConfig = input<any>();

  formNo = input();

  ngOnInit() {
    this.registerOnChangeAction();
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
  }

  isRequiredField = computed(
    () =>
      this.required() ||
      this.fieldControl()?.hasValidator(Validators.required) ||
      false
  );

  protected registerOnChangeAction() {
    this.fieldControl()?.valueChanges.subscribe((val) => {
      if (val !== this.value() && !this.isDestroyed) {
        this.value.set(val);
      }
    });
  }

  error = () => {
    const isInvalid =
      this.fieldControl()?.invalid &&
      (this.fieldControl()?.dirty || this.fieldControl()?.touched);
    if (!isInvalid) return '';

    const errors = this.fieldControl()?.errors || {};
    const firstKey = Object.keys(errors)?.[0];
    if (this.fieldControl()?.errors?.['required']) {
      return 'This field is required';
    }

    if (this.fieldControl()?.errors?.['email']) {
      return 'Please enter a valid email address';
    }
    if (firstKey) {
      return this.fieldControl()?.errors?.[firstKey];
    }
    return '';
  };

  protected onChange: (value: string | number | boolean | any[]) => void = () =>
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onTouched: () => void = () => {};

  writeValue(obj: any): void {
    if (this.isDestroyed) {
      return;
    }
    this.value.set(obj);
    this.onChange(obj);
    this.fieldControl()?.patchValue(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onBlur() {
    this.fieldControl()?.markAsTouched();
    this.onTouched();
  }
}
