import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { ClientAuthService } from '../services/auth.service';

export function identityInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const authService = inject(ClientAuthService);
  // const sid = storageService.getSid() || '';

  // const newHeader = req.clone({
  //   setHeaders: {
  //     Authorization: `Bearer ${sid}`,
  //   },
  // });

  return next(req).pipe(
    catchError((e) => {
      console.log('e >>>> ', e);
      // if (window && e.url.startsWith(window.location.origin))
      //   return of({} as any);

      if (e.status === 419) {
        authService.logout();
      }
      return of({} as any);
    })
  );
}
