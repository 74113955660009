import { CommonModule } from '@angular/common';
import { Component, inject, model, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@codeandweb/ngx-translate';
import { DrawerModule } from 'primeng/drawer';
import { ACCOUNT_SIDE_BAR_ITEMS } from '../../constants/account-side-bar';
import { ClientAuthService } from '../../services/auth.service';
import { BankingActionsComponent } from '../banking-actions/banking-actions.component';
import { BrandLogoComponent } from '../brand-logo/brand-logo.component';
import { CreditBalanceComponent } from '../header/credit-balance/credit-balance.component';
import { DailyRewardComponent } from '../header/daily-reward/daily-reward.component';
import { JoinNowBtnComponent } from '../header/join-now-btn/join-now-btn.component';
import { LoginBtnComponent } from '../header/login-btn/login-btn.component';
import { PlayerInfoComponent } from '../header/player-info/player-info.component';
import { ToggleLanguageComponent } from '../header/toggle-language/toggle-language.component';
import { CategoryComponent } from '../home-page/category/category.component';
import { IconComponent } from '../icon/icon.component';
import { SearchBoxComponent } from '../search-box/search-box.component';

@Component({
  selector: 'right-drawer',
  templateUrl: './right-drawer.component.html',
  imports: [
    CommonModule,
    DrawerModule,
    LoginBtnComponent,
    JoinNowBtnComponent,
    BrandLogoComponent,
    CategoryComponent,
    PlayerInfoComponent,
    CreditBalanceComponent,
    DailyRewardComponent,
    IconComponent,
    ToggleLanguageComponent,
    BankingActionsComponent,
    SearchBoxComponent,
    TranslateModule,
  ],
})
export class RightDrawerComponent {
  visible = model(false);
  showSearchBox = model(false);
  authService = inject(ClientAuthService);
  router = inject(Router);
  messageCount = signal(0);
  sideBarItems = ACCOUNT_SIDE_BAR_ITEMS;

  isLogin = this.authService.isLogin;

  closeDialog() {
    this.visible.set(false);
  }

  goToPage(path: string) {
    this.closeDialog();
    this.router.navigateByUrl(path);
  }

  logout() {
    this.authService.logout();
  }

  isActiveItem(path: string) {
    return this.router.url.includes(path);
  }
}
