<div class="bg-footer pt-4 mt-4 pb-[60px]">
  <div class="max-w-[1200px] mx-auto px-[10px]">
    <div class="flex flex-wrap justify-between">
      <div class="flex flex-wrap items-center space-x-2 mb-[15px]">
        @for(item of seoContent(); track $index) {
        <div
          class="flex items-center border-r border-r-[#ccc] pr-2.5 last:border-r-0"
        >
          <h2
            class="font-semibold hover:text-warning text-primary cursor-pointer"
            (click)="tabClicked(item)"
          >
            {{ item.title }}
          </h2>
        </div>
        }
      </div>
      <p class="basis-full 1220:basis-auto text-end mb-[15px]">
        {{ "COPYRIGHT" | translate }}
      </p>
    </div>
    <div class="footer-content py-4" [innerHTML]="footerHtml()"></div>
  </div>
  <div class="bg-footer-secondary pt-[20px]">
    <app-service-provider-platform />
    <app-site-info></app-site-info>
  </div>

  <ng-container *ngTemplateOutlet="footerMobile"></ng-container>
</div>

<ng-template #footerMobile>
  <div
    class="fixed 1220:hidden w-full bottom-0 right-0 border-t border-t-primary text-white flex items-center justify-between bg-card-secondary"
  >
    @if(login()) {
    <div class="py-[10px] px-[20px] flex justify-between w-full">
      @for(item of mobileMenu(); track $index) {
      <div
        class="flex flex-col items-center gap-1"
        (click)="goToPage(item.page)"
      >
        <p class="w-[15px] h-[15px] 900:w-[24px] 900:h-[24px]">
          <core-icon [path]="item.icon" class="bg-white" />
        </p>
        <p class="text-white text-[12px] 900:text-[14px]">{{ item.title }}</p>
      </div>
      }
    </div>
    } @else {
    <div class="flex 600:hidden w-full">
      <div class="w-1/2 flex justify-center border-primary border-r">
        <login-btn type="square" />
      </div>
      <div class="w-1/2 flex justify-center p-4">
        <join-now-btn type="square" />
      </div>
    </div>
    }
  </div>
</ng-template>
