import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  makeStateKey,
  provideAppInitializer,
  provideExperimentalZonelessChangeDetection,
  StateKey,
  TransferState,
} from '@angular/core';
import {
  provideClientHydration,
  withEventReplay,
} from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { TranslateHttpLoader } from '@codeandweb/http-loader';
import {
  provideTranslateService,
  TranslateLoader,
} from '@codeandweb/ngx-translate';
import Lara from '@primeng/themes/lara';
import { MessageService } from 'primeng/api';
import { providePrimeNG } from 'primeng/config';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { register } from 'swiper/element/bundle';
import { identityInterceptor } from '../guards/identityInterceptor';
import { MyErrorHandler } from '../services/error.service';
import { routes } from './app.routes';
import { initRouters } from './routes';

class TranslateBrowserLoader implements TranslateLoader {
  constructor(private http: HttpClient, private transferState: TransferState) {}

  public getTranslation(lang: string): Observable<any> {
    const key: StateKey<number> = makeStateKey<number>(
      'transfer-translate-' + lang
    );
    const data = this.transferState.get(key, null);

    if (data) {
      return new Observable((observer) => {
        observer.next(data);
        observer.complete();
      });
    } else {
      return new TranslateHttpLoader(
        this.http,
        './locales/',
        '.json'
      ).getTranslation(lang);
    }
  }
}

const httpLoaderFactory: (
  http: HttpClient,
  transferState: TransferState
) => TranslateBrowserLoader = (
  http: HttpClient,
  transferState: TransferState
) => {
  return new TranslateBrowserLoader(http, transferState);
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, TransferState],
      },
      defaultLanguage: 'en',
    }),
    { provide: ErrorHandler, useClass: MyErrorHandler },
    provideAppInitializer(() => {
      register();
      initRouters();
    }),
    provideHttpClient(withFetch(), withInterceptors([identityInterceptor])),
    provideRouter(routes),
    provideClientHydration(withEventReplay()),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: Lara,
      },
    }),

    provideExperimentalZonelessChangeDetection(),
    MessageService,
    DialogService,
  ],
};
