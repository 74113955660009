@if(type() === 'primary') {
<button
  (click)="showLoginDialog()"
  class="flex items-center gap-2 hover:bg-primary/30 rounded px-2 hover:cursor-pointer"
>
  <span class="w-[16px] h-[16px]">
    <core-icon path="/icons/user.svg" class="bg-white/80" />
  </span>
  <span class="text-gray">{{ "SIGN_IN" | translate }}</span>
</button>
} @if(type() === 'hexagon') {
<core-button class="" (click)="showLoginDialog()" type="hexagon">
  <span class="text-[18px]">{{ "SIGN_IN" | translate }}</span>
</core-button>
} @if(type() === 'square') {
<button
  (click)="showLoginDialog()"
  class="flex items-center bg-primary w-full h-full flex justify-center gap-2 hover:bg-primary/30 px-2 hover:cursor-pointer"
>
  <span class="text-black">{{ "SIGN_IN" | translate }}</span>
</button>
}

<p-dialog
  [modal]="true"
  [appendTo]="'body'"
  [(visible)]="visible"
  [dismissableMask]="true"
  [modal]="true"
  [style]="{ width: '25rem' }"
  styleClass="[&_.p-dialog-content]:bg-card !border-none"
  ><button
    (click)="closeDialog()"
    class="w-[28px] h-[28px] absolute top-[10px] right-[10px] hover:cursor-pointer"
  >
    <img src="/icons/close-square.svg" alt="close" />
  </button>
  <div class="flex flex-col gap-4 pt-[24px]">
    @if(logo()) {
    <img
      tabindex="0"
      [src]="$any(logo())"
      width="220"
      height="60"
      class="mx-auto cursor-pointer"
      (click)="router.navigateByUrl('/')"
      alt="logo"
    />
    <hr />
    }
    <p class="flex justify-between items-center">
      <span class="text-center text-[24px] text-primary font-bold">Login</span>
    </p>
    <input
      type="text"
      placeholder="Username"
      [(ngModel)]="user().userName"
      class="w-3/4 p-[15px_25px] focus:outline-none bg-transparent border-card-white border hover:border-primary clip-path"
    />

    <input
      type="password"
      placeholder="Password"
      [(ngModel)]="user().password"
      class="w-3/4 p-[15px_25px] focus:outline-none !bg-transparent border-card-white border hover:border-primary clip-path"
    />

    <div class="flex justify-between">
      <a class="text-white" href="/forgot-password">{{
        "FORGOT_PASSWORD" | translate
      }}</a>

      <a class="text-primary" href="/register">{{
        "CREATE_ACCOUNT" | translate
      }}</a>
    </div>
    <core-button
      class="[&_button]:min-h-[unset] [&_button]:p-[5px_15px] [&_button]:font-semibold"
      type="primary"
      [clipPath]="true"
      (click)="login()"
      [disabled]="user().userName === '' || user().password === ''"
      ><span class="text-[20px]">Login</span></core-button
    >
  </div>
</p-dialog>
