import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ClientAuthService } from '../services/auth.service';

export const isLogin: CanActivateFn = () => {
  // const storageService = inject(StorageService);
  const platformId = inject(PLATFORM_ID);
  const authService = inject(ClientAuthService);
  // // const response: any = inject('RESPONSE' as any);
  const router = inject(Router);

  if (isPlatformServer(platformId)) {
    return false;
  }

  if (authService.isLogin()) {
    return true;
  }

  router.navigate(['/']);
  return false;
};
