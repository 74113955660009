import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@codeandweb/ngx-translate';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-site-info',
  templateUrl: './site-info.component.html',
  imports: [CommonModule, TranslateModule, IconComponent],
})
export class SiteInfoComponent {}
