import { inject, Injectable } from '@angular/core';
import { DispatchPlayGame } from '../models/dispatch.model';
import { ClientHttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class DispatchService {
  httpService = inject(ClientHttpService);

  dispatchGame(
    gameProviderCode: string,
    gameCode: string | undefined,
    isDemo = false
  ) {
    const payload: DispatchPlayGame = {
      gameProviderCode,
      lobbyUrl:
        window.location.origin === 'http://localhost:4200'
          ? 'https://client.lvlupgaming.org'
          : window.location.origin,
      cashierUrl:
        window.location.origin === 'http://localhost:4200'
          ? 'https://client.lvlupgaming.org'
          : window.location.origin,
    };

    if (gameCode) {
      payload.gameCode = gameCode;
    }

    return this.dispatch(payload, isDemo);
  }

  private dispatch(payload: DispatchPlayGame, isDemo = false) {
    const url = isDemo
      ? `/game-svc/v1/play-game/demo`
      : `/game-svc/v1/play-game`;

    return this.httpService.post<
      DispatchPlayGame,
      { data: { data: string; responseType: 'html' | 'url' } }
    >(url, payload);
  }

  openDispatchWindow(url: string) {
    const w = 800;
    const h = 600;
    const dualScreenLeft =
      window.screenLeft !== undefined
        ? window.screenLeft
        : (window.screen as unknown as { availLeft: number })['availLeft'];
    const dualScreenTop =
      window.screenTop !== undefined
        ? window.screenTop
        : (window.screen as unknown as { availTop: number })['availTop'];

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const left = width / 2 - w / 2 + dualScreenLeft;
    const top = height / 2 - h / 2 + dualScreenTop;

    const newWindow = window.open(
      url,
      'newWindow',
      `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}, resizable=yes,status=yes`
    );

    if (newWindow) {
      newWindow.focus();
    }
  }
}
