<div
  [pTooltip]="tooltipContent"
  tooltipPosition="bottom"
  class=""
  tooltipStyleClass="[&_.p-tooltip-text]:!p-0 [&_.p-tooltip-text]:[background:unset] !pointer-events-auto"
  autoHide="false"
>
  <p
    class="flex gap-2 items-center hover:cursor-pointer"
    (click)="reloadBalance()"
    [tabindex]="1"
  >
    <core-icon
      path="/icons/reload.svg"
      class="!bg-primary !w-4 !h-4"
      [ngClass]="{ 'animate-spin': isLoading() }"
    />
    <span class="text-primary-600 font-DigitalSans">{{
      balance() | currency : "IDR " : "symbol" : "1.2-2"
    }}</span>
  </p>
  <ng-template #tooltipContent>
    <!-- <div class="border-special !rounded-lg min-w-[250px]">
      <div class="p-2 bg-black/85 flex flex-col max-h-[600px]">
        <div
          class="flex justify-between uppercase border-b pb-2 text-primary font-semibold"
        >
          {{ "CREDIT_BALANCE" | translate }}
          <span class="font-DigitalSans">{{
            balance() | currency : "IDR " : "" : "1.2-2"
          }}</span>
        </div>
        <div class="overflow-y-auto grow scrollbar">
          @for(category of balanceItems(); track category.title) {
          <div class="flex flex-col">
            <span class="text-primary uppercase mt-4">{{
              category.title
            }}</span>
            <div class="grid grid-cols-1 gap-[5px]">
              @for(provider of category.providers; track provider.providerName)
              {
              <div class="flex justify-between">
                <span class="ml-2">{{ provider.providerName }}</span>
                <span class="font-DigitalSans">{{
                  provider.amount | currency : "IDR " : "" : "1.2-2"
                }}</span>
              </div>
              }
            </div>
          </div>
          }
        </div>
      </div>
    </div> -->
  </ng-template>
</div>
