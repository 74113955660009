import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { HomepageService } from '../services/homepage.service';
import { MetaService } from '../services/meta.service';

export const initRouters = () => {
  initProviderRouters();
  initStaticPageRouters();
};

const initProviderRouters = () => {
  const homepageService = inject(HomepageService);
  const router = inject(Router);

  homepageService
    .getMetadata()
    .pipe(map((val) => val.menu))
    .subscribe((val) => {
      const mainRouterConfig = router.config.find((i) => i.path === '');
      if (!mainRouterConfig) return;
      const providersConfig = mainRouterConfig?.children?.find(
        (i) => i.path === ':providers'
      );

      val.forEach((item) => {
        mainRouterConfig?.children?.push({
          ...providersConfig,
          path: item.urlPath.replace(/^\//, ''),
        });
      });

      mainRouterConfig.children = mainRouterConfig.children?.filter(
        (i) => i.path !== ':providers'
      );

      router.resetConfig(router.config);
    });
};

const initStaticPageRouters = () => {
  const router = inject(Router);
  const metaService = inject(MetaService);

  metaService
    .getAllSEOContent()
    .pipe(map((res) => res.data))
    .subscribe((val) => {
      const mainRouterConfig = router.config.find((i) => i.path === '');
      if (!mainRouterConfig) return;
      const staticPagesConfig = mainRouterConfig?.children?.find(
        (i) => i.path === ':footerStaticPage'
      );

      val.forEach((item) => {
        mainRouterConfig?.children?.push({
          ...staticPagesConfig,
          path: item.page.replace(/^\//, ''),
        });
      });

      mainRouterConfig.children = mainRouterConfig.children?.filter(
        (i) => i.path !== ':footerStaticPage'
      );

      router.resetConfig(router.config);
    });
};
