<div
  class="z-50 transition-all duration-300 top-0 w-full border-b border-b-primary 1220:border-b-0"
>
  <download-app-banner />
  <div class="flex flex-col justify-center min-h-[50px]">
    <div
      class="flex items-center fixed top-0 1220:static bg-header-secondary 1220:bg-header border-b border-b-primary 1220:border-b-0 w-screen z-50 py-[5px] pr-[10px]"
    >
      <brand-logo
        class="block 1220:hidden ml-[20px] 900:ml-[40px]"
      ></brand-logo>
      <div class="hidden 900:flex items-center gap-4 divide-x px-6">
        <p
          class="flex items-center gap-2 text-gray hover:text-white hover:cursor-pointer"
        >
          <span class="w-[20px] h-[20px]">
            <core-icon
              path="/icons/call-service.svg"
              alt="call-service"
              class="bg-white/80"
            />
          </span>
          Support
        </p>
        <toggle-language />
      </div>
      @if(isLogin() !== null) {
      <div class="flex gap-2 p-2 ml-auto">
        @if(isLogin()) {
        <div
          class="hidden 900:flex gap-4 items-center bg-primary-800 px-2 rounded"
        >
          <player-info />
          <div class="border-r border-r-white/80 h-2/3 w-[1px]"></div>
          <credit-balance />
          <div class="border-r border-r-white/80 h-2/3 w-[1px]"></div>
          <!-- <daily-reward /> -->

          <img
            tabindex="0"
            (click)="goToPage('account-center/my-account')"
            src="/icons/user.svg"
            alt="avt"
            class="w-8 bg-primary rounded p-2 hover:cursor-pointer"
          />
          <img
            tabindex="1"
            (click)="goToPage('account-center/bank')"
            src="/icons/bank.svg"
            href="/account-center/bank"
            alt="avt"
            class="w-8 bg-primary rounded p-2 hover:cursor-pointer"
          />
          <div
            tabindex="2"
            class="w-8 aspect-square bg-button-danger border-2 border-primary rounded p-1 hover:cursor-pointer"
            (click)="logout()"
          >
            <core-icon class="bg-primary" path="/icons/logout.svg" />
          </div>
        </div>

        } @else {
        <div class="hidden 600:grid place-items-center">
          <login-btn />
        </div>

        <div class="hidden 600:block 900:hidden">
          <join-now-btn />
        </div>

        }
      </div>
      <right-drawer class="block 900:hidden" />
      }
    </div>
    <div
      class="flex relative 1220:ml-[40px] justify-center 1220:justify-start items-baseline 1220:items-center bg-header-secondary after:absolute after:content-[''] after:h-full after:border-r-[35px] after:border-r-transparent after:border-b after:border-b-transparent 1220:after:border-b-global after:border-b-[90px]"
    >
      <div class="hidden 1220:block ml-[40px]">
        <brand-logo />
      </div>
      <div
        class="hidden 900:block 900:mt-[67px] 1220:mt-0 grow 1220:grow-0 1220:ml-auto 1220:mr-[20px]"
      >
        <app-category ngSkipHydration />
      </div>
      <div class="hidden 900:block">
        <join-now-btn />
      </div>
    </div>
  </div>
</div>
