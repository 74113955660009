import {
  Inject,
  inject,
  Injectable,
  makeStateKey,
  Optional,
  TransferState,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { map, of } from 'rxjs';
import { REQUEST } from '../app/express.token';
import { environment } from '../environments/environment';
import { ClientHttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private meta = inject(Meta);
  private title = inject(Title);
  private httpService = inject(ClientHttpService);
  private transferState = inject(TransferState);
  constructor(@Optional() @Inject(REQUEST) private request: Request) {}

  setUpMetaTag() {
    // const url = this.request?.url;
    this.title.setTitle(environment.defaultTitle);
    return of({});
  }

  setTitle(title: string) {
    this.meta.updateTag({ name: 'title', content: title });
  }

  getSetting() {
    const key = makeStateKey<{ data: { footerContent: string } }>('websetting');

    const serverData = this.transferState.get(key, null);
    if (serverData) {
      return of(serverData);
    }

    return this.httpService
      .get<{ data: { footerContent: string } }>(
        '/setting-svc/v1/websetting',
        true
      )
      .pipe(
        map((res) => {
          this.transferState.set(key, res);
          return res;
        })
      );
  }

  getAllSEOContent() {
    const key = makeStateKey<{ data: { title: string; page: string }[] }>(
      'seo-content-management'
    );

    const serverData = this.transferState.get(key, null);
    if (serverData) {
      return of(serverData);
    }

    return this.httpService
      .get<{ data: { title: string; page: string }[] }>(
        '/seo-content-management',
        true
      )
      .pipe(
        map((res) => {
          this.transferState.set(key, res);
          return res;
        })
      );
  }

  getSEOContentByPage(page: string) {
    return this.httpService
      .get<{ data: { content: string } }>(
        `/seo-content-management/page/${page}`,
        true
      )
      .pipe(map((val) => val.data.content));
  }
}
