@if(providerImages()?.length){
<p
  class="text-2xl font-bold text-primary mt-8 pt-8 px-4 max-w-[1200px] mx-auto"
>
  {{ "SERVICE_PROVIDER_PLATFORM" | translate }}
</p>
<div class="flex flex-wrap gap-4 p-4 max-w-[1200px] mx-auto">
  @for(game of providerImages(); track game.title){ @if(game.logoViewUrls.length
  > 0){
  <div class="border-special relative mt-2">
    <fieldset class="bg-transparent h-full">
      <legend
        class="absolute top-0 left-2 -translate-y-1/2 bg-global p-2 text-[16px] text-primary"
      >
        {{ game.title }}
      </legend>
      <div class="flex gap-2 p-2 flex-wrap">
        @for(image of game.logoViewUrls; track $index){
        <img
          [src]="image"
          alt="game"
          class="filter grayscale hover:grayscale-0"
        />
        }
      </div>
    </fieldset>
  </div>
  } }
</div>
}
