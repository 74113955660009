import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IconComponent } from '../icon/icon.component';
@Component({
  selector: 'banking-actions',
  template: ` <div class="grid grid-cols-2 gap-2">
    @for(action of actions; track $index) {
    <div
      class="flex flex-col items-center justify-center gap-2 border rounded border-primary p-2"
      (click)="goToPage(action.routerLink)"
    >
      <span class="w-[20px] aspect-square">
        <core-icon [path]="action.icon" class="bg-white" />
      </span>
      <p>{{ action.label }}</p>
    </div>
    }
  </div>`,
  imports: [CommonModule, IconComponent],
})
export class BankingActionsComponent {
  router = inject(Router);
  actions = [
    {
      icon: '/icons/deposit.svg',
      label: 'Deposit',
      value: 'DEPOSIT',
      routerLink: '/account-center/bank/deposits',
    },
    {
      icon: '/icons/withdrawal-active.svg',
      label: 'Withdraw',
      value: 'WITHDRAW',
      routerLink: '/account-center/bank/withdrawals',
    },
    // {
    //   icon: '/icons/loyalty-redemption-active.svg',
    //   label: 'Redemption',
    //   value: 'REDEMPTION',
    //   routerLink:
    //     'https://redemption.nxsbrand.com/Redemption/RedemptionStore/OV2:176b7350-0703-4a5a-ab23-b7fccaff90f7?culture=en-US',
    // },
  ];

  goToPage(url: string) {
    if (url.startsWith('/')) {
      this.router.navigate([url]);
    } else {
      window.open(url, '_blank');
    }
  }
}
