import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ClientAuthService } from '../services/auth.service';

export const isNotLogin: CanActivateFn = () => {
  // const storageService = inject(StorageService);
  const platformId = inject(PLATFORM_ID);
  const authService = inject(ClientAuthService);

  if (isPlatformServer(platformId)) {
    return true;
  }

  if (!authService.isLogin()) {
    return true;
  }

  return false;
};
