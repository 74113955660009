<div
  class="flex hover:cursor-pointer gap-2 items-center"
  (click)="toMyAccountPage()"
>
  <div class="w-[22px] h-[22px]">
    <img src="/icons/bronze.svg" alt="player-level" />
  </div>
  <div class="flex flex-col min-w-[80px] max-w-[120px] gap-[2px] py-1">
    <p>{{ userInfo()?.loginName }}</p>
    <div
      class="h-[5px] w-full bg-white/30 rounded-2xl"
      [pTooltip]="tooltipContent"
      tooltipPosition="bottom"
      autoHide="false"
      tooltipStyleClass="[&_.p-tooltip-text]:border [&_.p-tooltip-text]:rounded-xl"
    >
      <div
        class="h-[5px] bg-primary rounded-xl"
        [style.width]="
          playerScore() ? (playerScore() * 100) / maxLevelScore() + '%' : '0%'
        "
      ></div>
    </div>
  </div>
  <ng-template #tooltipContent>
    <div class="w-fit">
      {{ formatNumber(playerScore()) }} / {{ formatNumber(maxLevelScore()) }}
    </div>
  </ng-template>
</div>
