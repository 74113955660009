<div>
  <button
    class="border-2 border-primary p-2 grid place-items-center"
    (click)="visible.set(true)"
  >
    <i class="pi pi-bars text-primary"></i>
  </button>
</div>

<p-drawer
  [(visible)]="visible"
  position="right"
  styleClass="!bg-global !border-primary [&_.p-drawer-header]:hidden [&_.p-drawer-content]:pt-[30px]"
  [showCloseIcon]="false"
>
  <button
    (click)="closeDialog()"
    class="w-[28px] h-[28px] absolute top-[10px] right-[10px] hover:cursor-pointer"
  >
    <img src="/icons/close-square.svg" alt="close" />
  </button>

  <div class="flex flex-col h-full gap-4">
    <brand-logo />
    <div class="flex gap-4 divide-x">
      <p
        class="flex items-center gap-2 text-gray hover:text-white hover:cursor-pointer"
      >
        <span class="w-[20px] h-[20px]">
          <core-icon
            path="/icons/call-service.svg"
            alt="call-service"
            class="bg-white/80"
          />
        </span>
        Support
      </p>
      <toggle-language />
    </div>

    @if(isLogin() !== true) {

    <ng-container *ngTemplateOutlet="nonAuthTemplate"></ng-container>
    } @if(isLogin()) {
    <ng-container *ngTemplateOutlet="authTemplate"></ng-container>
    }
  </div>
</p-drawer>

<ng-template #nonAuthTemplate>
  <div class="flex 600:hidden items-center justify-center pt-4">
    <login-btn type="hexagon" />
    <join-now-btn type="hexagon" />
  </div>
</ng-template>

<ng-template #authTemplate>
  <div class="flex flex-col gap-4 bg-primary-800 grow text-white">
    <player-info />
    <credit-balance />
    <!-- <daily-reward [showText]="true" /> -->

    <banking-actions />

    <core-search-box (click)="showSearchBox.set(true)" />

    <div>
      @for (item of sideBarItems; track $index) {
      <div
        tabindex="{{ $index }}"
        [ngClass]="{ 'bg-primary': isActiveItem(item.path) }"
        class="p-4 mb-1 hover:bg-primary hover:cursor-pointer uppercase flex gap-[10px]"
        (click)="goToPage(item.path)"
      >
        <core-icon
          [path]="item.imgUrl"
          alt="Side bar icon"
          class="!w-[20px] !h-[20px] bg-primary"
          [ngClass]="{ 'bg-white': isActiveItem(item.path) }"
        />
        {{ item.label | translate }}

        @if(item.label === 'MESSAGE') {
        <p class="ml-auto">[{{ messageCount() }}]</p>
        }
      </div>
      }
    </div>

    <div
      class="flex items-center gap-2 mt-auto text-white uppercase border-t border-t-primary pt-4"
    >
      <div
        tabindex="2"
        class="w-8 aspect-square bg-button-danger border-2 border-primary rounded p-1 hover:cursor-pointer"
        (click)="logout()"
      >
        <core-icon class="bg-primary" path="/icons/logout.svg" />
      </div>
      Log out
    </div>
  </div>
</ng-template>
