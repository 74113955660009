<div class="flex relative [&_input]:pl-8">
  <div class="absolute top-0 z-10 w-8 h-8 p-2">
    <core-icon class="bg-white" path="/icons/search.svg"></core-icon>
  </div>
  <input
    type="text"
    class="!bg-transparent px-2 border border-border-control rounded py-1 w-full"
    pInputText
    [value]="value()"
    (input)="writeValue($event)"
    [placeholder]="placeholder()"
  />
</div>
