import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ClientAuthService } from '../services/auth.service';
import { PlayerService } from '../services/player.service';
export const transactionGuard: CanActivateFn = async function () {
  const authService = inject(ClientAuthService);
  const playerService = inject(PlayerService);
  const router = inject(Router);

  const { recId } = authService.userInfo() || {};

  if (recId) {
    playerService.getCurrentPendingTransaction(recId).subscribe({
      next: (res: any) => {
        playerService.currentPendingTransaction.set(res);
        const { numOfLimit, totalPendingTransaction } = res;
        if (totalPendingTransaction >= numOfLimit) {
          router.navigate(['']);
          playerService.showTransactionStatus.set(true);
          return false;
        }
        return true;
      },
      error: () => {
        return true;
      },
    });
  }

  return true;
};
