import { Component, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { ClientAuthService } from '../../../services/auth.service';
import { ButtonComponent } from '../../button/button.component';

@Component({
  selector: 'join-now-btn',
  standalone: true,
  imports: [ButtonComponent],
  template: ` @if(!isLoggedIn()) {@if(type() === 'primary') {
    <core-button
      class="[&_button]:min-h-[unset] 1220:mr-[40px] mr-[20px] ml-[20px]"
      (click)="router.navigateByUrl('/register')"
      type="glow"
      ><span class="text-[18px]">JOIN NOW</span></core-button
    >} @if(type() === 'hexagon') {
    <core-button
      class="[&_button]:min-h-[unset] 1220:mr-[40px] mr-[20px] ml-[20px]"
      type="hexagon"
      [outline]="true"
      (click)="router.navigateByUrl('/register')"
      ><span class="text-[18px]">REGISTER</span></core-button
    >
    } @if(type() === 'square') {
    <button
      (click)="router.navigateByUrl('/register')"
      class="flex items-center gap-2 hover:bg-primary/30 rounded px-2 hover:cursor-pointer"
    >
      <span class="text-gray">REGISTER</span>
    </button>
    }}`,
})
export class JoinNowBtnComponent {
  type = input<'primary' | 'hexagon' | 'square'>('primary');
  router = inject(Router);
  authService = inject(ClientAuthService);
  isLoggedIn = this.authService.isLogin;
}
