import { Component, computed, input, viewChild } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'horizontal-scroll',
  template: ` <div class="w-full flex items-center gap-1">
    @if (canScrollLeft()) {
    <button (click)="scrollPrev()" class="w-[20px] h-[20px]">
      <core-icon
        path="/icons/triangle.svg"
        class="bg-white -rotate-90"
      /></button
    >}
    <div
      class="flex ml-auto overflow-x-auto w-full scrollbar {{
        containerClass()
      }}"
      #container
    >
      <ng-content></ng-content>
    </div>
    @if (canScrollRight()) {
    <button (click)="scrollNext()" class="w-[20px] h-[20px]">
      <core-icon path="/icons/triangle.svg" class="bg-white rotate-90" />
    </button>
    }
  </div>`,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  imports: [IconComponent],
})
export class HorizontalScrollComponent {
  container = viewChild<{ nativeElement: HTMLDivElement }>('container');
  containerClass = input<string>();

  canScrollLeft = computed(() => {
    const container = this.container();
    if (!container) return false;

    return (
      container.nativeElement.scrollWidth > container.nativeElement.clientWidth
    );
  });

  canScrollRight = computed(() => {
    const container = this.container();
    if (!container) return false;
    return (
      container.nativeElement.scrollLeft <
      container.nativeElement.scrollWidth - container.nativeElement.clientWidth
    );
  });

  scrollPrev() {
    const container = this.container();
    if (!container) return;
    container.nativeElement.scrollTo({
      left: container.nativeElement.scrollLeft - 100,
      behavior: 'smooth',
    });
  }

  scrollNext() {
    const container = this.container();
    if (!container) return;
    container.nativeElement.scrollTo({
      left: container.nativeElement.scrollLeft + 100,
      behavior: 'smooth',
    });
  }
}
