@if(direction() === 'horizontal') {
<ng-container *ngTemplateOutlet="horizontalTemplate"></ng-container>
} @if(direction() === 'vertical') {
<ng-container *ngTemplateOutlet="verticalTemplate"></ng-container>
}

<ng-template #horizontalTemplate>
  <div class="flex flex-col items-center gap-4 w-full">
    <horizontal-scroll containerClass="justify-left 900:justify-center">
      @for(item of gameCategories(); track $index) {
      <div
        tabindex="0"
        class="py-5 rounded hover:cursor-pointer min-w-[77px] hover:bg-primary/20"
        [ngClass]="{
          'border-t-2 border-primary': isActive(item.urlPath)
        }"
        (click)="handleClickMenu(item.urlPath)"
        (mouseover)="handleMouseOver($event, op, item.providers)"
      >
        <!-- <core-icon [path]="item.imageUrl" [ngClass]="{'bg-primary'}" /> -->
        <div class="flex flex-col items-center justify-center">
          <img [src]="item.imageUrl" [alt]="item.title" [width]="25" />
          <span
            class="text-white text-[12px] mt-2 whitespace-nowrap uppercase"
            [ngClass]="{
              '!text-primary': isActive(item.urlPath)
            }"
            >{{ item.title | translate }}</span
          >
        </div>
      </div>

      }

      <p-popover
        #op
        styleClass="hidden 1220:block mt-0 w-screen [&_.p-popover-content]:p-0 !border-none !mt-0 before:hidden after:hidden"
      >
        @if(selectedCategory().length > 0) {
        <div
          class="bg-header-secondary w-full border-2 border-primary border-dashed"
          (mouseout)="op.hide()"
          (mouseover)="op.show($event)"
        >
          <ng-container *ngTemplateOutlet="listProviderTemplate"></ng-container>
        </div>
        }
      </p-popover>
    </horizontal-scroll>
    @if(selectedCategory().length > 0) {
    <div class="block 1220:hidden">
      <ng-container *ngTemplateOutlet="listProviderTemplate"></ng-container>
    </div>
    }
  </div>
</ng-template>

<ng-template #listProviderTemplate>
  <div
    class="bg-header-secondary grid grid-cols-2 600:grid-cols-4 900:grid-cols-5 1220:grid-cols-6 gap-4 p-4"
  >
    @for(i of selectedCategory(); track $index) {
    <div
      tabindex="{{ ($index + 1) * 10 }}"
      class="flex flex-col items-center rounded-full border-white hover:border-primary hover:scale-110 hover:border-2 border overflow-hidden max-w-[220px] max-h-[110px] relative"
    >
      <img
        tabindex="{{ ($index + 1) * 10 }}"
        [src]="i.image"
        [alt]="i.providerCode"
        width="100%"
        class="hover:cursor-pointer"
        (click)="handleClick(i)"
        [ngClass]="{
          'opacity-50 grayscale hover:cursor-default': i.isMaintenance
        }"
      />

      @if(i.isMaintenance) {
      <div
        class="absolute top-0 left-0 w-full h-full bg-black/50 text-white grid place-items-center"
      >
        <span class="bg-gray-400 px-2 rounded">{{
          "MAINTENANCE" | translate
        }}</span>
      </div>
      }
    </div>
    }
  </div>
</ng-template>

<ng-template #verticalTemplate>
  <div class="flex ml-auto overflow-x-auto w-full justify-center">
    <p-accordion value="0">
      @for(item of gameCategories(); track $index) {
      <p-accordion-panel [value]="$index">
        <p-accordion-header class="!bg-transparent !border-t-0 !border-x-0"
          ><div class="flex items-center justify-center gap-2">
            <img [src]="item.imageUrl" [alt]="item.title" [width]="25" />
            <span
              class="text-white text-[12px] mt-2 whitespace-nowrap uppercase"
              [ngClass]="{
                '!text-primary': isActive(item.urlPath)
              }"
              >{{ item.title | translate }}</span
            >
          </div></p-accordion-header
        >
        <p-accordion-content
          class="[&_.p-accordioncontent-content]:!bg-header-secondary [&_.p-accordioncontent-content]:border-none"
        >
          <div class="flex flex-col gap-4">
            @for(i of item.providers; track i.providerCode) {
            <div
              class="grid place-items-center rounded-full border-white hover:border-primary hover:border-2 border overflow-hidden w-[220px] h-[110px] relative"
            >
              <img
                [src]="i.image"
                [alt]="i.providerCode"
                width="100%"
                class="hover:cursor-pointer"
              />
            </div>
            }
          </div>
        </p-accordion-content>
      </p-accordion-panel>

      }
    </p-accordion>
  </div>
</ng-template>
