import { CommonModule } from '@angular/common';
import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlComponent } from '../base/control/control.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'core-search-box',

  imports: [CommonModule, ReactiveFormsModule, IconComponent],
  templateUrl: './search-box.component.html',
  styleUrl: './search-box.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBoxComponent),
      multi: true,
    },
  ],
})
export class SearchBoxComponent extends ControlComponent {
  override writeValue(obj: any): void {
    if (!obj) return;

    if (typeof obj === 'string') {
      super.writeValue(obj);
      return;
    }

    super.writeValue(obj.target.value);
  }
}
