import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';

@Component({
  selector: 'download-app-banner',
  template: `
    <!-- <div
      class="flex items-center p-2 gap-4 relative 900:hidden bg-card"
      [ngClass]="{ hidden: !isShowBanner() }"
    >
      <div class="bg-primary rounded p-2">
        <img
          src="/icons/mobile-application.svg"
          alt="icon"
          class="w-[20px] h-[20px]"
        />
      </div>
      <div>
        <p>Mobile Application</p>
        <p>Don't show again today</p>
      </div>

      <core-button class="ml-auto mr-[25px]">Download</core-button>

      <button
        (click)="closeBanner()"
        class="w-[20px] h-[20px] absolute top-[5px] right-[5px] hover:cursor-pointer"
      >
        <img src="/icons/close-square.svg" alt="close" />
      </button>
    </div> -->
  `,
  imports: [ButtonComponent, CommonModule],
})
export class DownloadAppBannerComponent {
  isShowBanner = signal(true);
  closeBanner() {
    this.isShowBanner.set(false);
  }
}
