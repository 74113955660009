import { CommonModule } from '@angular/common';
import { Component, inject, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslatePipe } from '@codeandweb/ngx-translate';
import { AccordionModule } from 'primeng/accordion';
import { Popover, PopoverModule } from 'primeng/popover';
import { map } from 'rxjs';
import { HomepageMenuProviderModel } from '../../../models/homepage.model';
import { DispatchService } from '../../../services/dispatch.service';
import { HomepageService } from '../../../services/homepage.service';
import { HorizontalScrollComponent } from '../../horizontal-scroll/horizontal-scroll.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styles: `
  :host {
    display: contents;
  }
  `,
  imports: [
    CommonModule,
    PopoverModule,
    TranslatePipe,
    AccordionModule,
    HorizontalScrollComponent,
  ],
})
export class CategoryComponent {
  direction = input<'horizontal' | 'vertical'>('horizontal');
  router = inject(Router);
  homepageService = inject(HomepageService);
  dispatchService = inject(DispatchService);
  gameCategories = toSignal(
    this.homepageService.getMetadata().pipe(map((val) => val.menu))
  );

  selectedCategory = signal<HomepageMenuProviderModel[]>([]);

  logo = toSignal(
    this.homepageService.getMetadata().pipe(map((val) => val.merchantLogoImage))
  );

  handleMouseOver(e: any, op: Popover, childList: HomepageMenuProviderModel[]) {
    this.selectedCategory.set(childList);
    op.show(e);
  }

  handleClickMenu(path: string) {
    if (window.innerWidth >= 1220) {
      this.router.navigateByUrl(path);
    }
  }

  handleClick(item: HomepageMenuProviderModel) {
    if (item.isMaintenance) {
      return;
    }

    if (item.redirectType === 'desktop') {
      this.router.navigateByUrl(item.urlPath);
      return;
    }

    if (item.redirectType === 'dispatch') {
      this.dispatchService.openDispatchWindow(item.urlPath);
      return;
    }

    if (item.redirectType === 'iframe') {
      this.router.navigate(['dispatch', 'iframe', item.providerCode]);
      return;
    }
  }

  isActive(urlPath: string) {
    return this.router.url === urlPath;
  }
}
