import { Route } from '@angular/router';
import { MainLayoutComponent } from '../components/main-layout/main-layout.component';
import { transactionGuard } from '../guards/transaction.guard';
import { isLogin } from './isLogin.guard';
import { isNotLogin } from './isNotLogin.guard';
export const routes: Route[] = [
  {
    path: 'dispatch',
    pathMatch: 'prefix',
    children: [
      {
        path: 'game/:providerCode/:gameCode',
        loadComponent: () =>
          import('../pages/dispatch/dispatch.component').then(
            (m) => m.DispatchComponent
          ),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('../pages/home-page/home-page.component').then(
            (m) => m.HomePageComponent
          ),
      },
      {
        path: 'dispatch/iframe/:providerCode',
        loadComponent: () =>
          import('../pages/iframe/iframe.component').then(
            (m) => m.IframeComponent
          ),
      },
      {
        path: 'register',
        canActivate: [isNotLogin],
        loadComponent: () =>
          import(
            '../pages/account-center/register/register-page.component'
          ).then((m) => m.RegisterPageComponent),
      },

      {
        path: 'forgot-password',
        loadComponent: () =>
          import('../pages/forgot-password/forgot-password.component').then(
            (m) => m.ForgotPasswordComponent
          ),
      },
      {
        path: 'account-center',
        canActivate: [isLogin],
        loadComponent: () =>
          import('../pages/account-center/account-center.component').then(
            (m) => m.AccountCenterComponent
          ),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'my-account',
          },
          {
            path: 'my-account',
            loadComponent: () =>
              import(
                '../pages/account-center/my-account-page/my-account-page.component'
              ).then((m) => m.MyAccountPageComponent),
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'account-summary',
              },
              {
                path: 'account-summary',
                data: {
                  title: 'My Account',
                },
                loadComponent: () =>
                  import('../components/my-account/my-account.component').then(
                    (m) => m.MyAccountComponent
                  ),
              },
              {
                path: 'change-password',
                data: {
                  title: 'Change Password',
                },
                loadComponent: () =>
                  import(
                    '../pages/account-center/change-password/change-password.component'
                  ).then((m) => m.ChangePassWordComponent),
              },
              {
                path: 'update-details',
                loadComponent: () =>
                  import(
                    '../pages/account-center/update-account/update-account.component'
                  ).then((m) => m.UpdateAccountComponent),
              },
              {
                path: 'redemption-history',
                loadComponent: () =>
                  import(
                    '../pages/account-center/redemption-history/redemption-history.component'
                  ).then((m) => m.RedemptionHistoryComponent),
              },
            ],
          },
          {
            path: 'bank',
            canActivate: [transactionGuard],
            loadComponent: () =>
              import(
                '../pages/account-center/banking-page/banking-page.component'
              ).then((m) => m.BankingPageComponent),
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'deposits',
              },
              {
                path: 'deposits',
                data: {
                  title: 'Deposits',
                },
                loadComponent: () =>
                  import(
                    '../pages/account-center/banking-page/deposit/deposit.component'
                  ).then((m) => m.DepositComponent),
              },
              {
                path: 'withdrawals',
                data: {
                  title: 'Withdrawals',
                },
                loadComponent: () =>
                  import(
                    '../pages/account-center/banking-page/withdrawal/withdrawal.component'
                  ).then((m) => m.WithdrawalComponent),
              },
              {
                path: 'my-transactions',
                data: {
                  title: 'My Transactions',
                },
                loadComponent: () =>
                  import(
                    '../pages/account-center/banking-page/my-transactions/my-transactions.component'
                  ).then((m) => m.MyTransactionsComponent),
              },
              {
                path: 'my-bank-account',
                data: {
                  title: 'My Bank Account',
                },
                loadComponent: () =>
                  import(
                    '../pages/account-center/banking-page/my-bank-account/my-bank-account.component'
                  ).then((m) => m.MyBankAccountComponent),
              },
            ],
          },
          {
            path: 'loyalty-reward',
            loadComponent: () =>
              import(
                '../pages/account-center/loyalty-reward-page/loyalty-reward-page.component'
              ).then((m) => m.LoyaltyRewardComponent),
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'event-calendar',
              },
              {
                path: 'event-calendar',
                data: {
                  title: 'Event Calendar',
                },
                loadComponent: () =>
                  import(
                    '../components/loyalty-reward-page/event-calendar/event-calendar.component'
                  ).then((m) => m.EventCalendarComponent),
              },
              {
                path: 'missions',
                data: {
                  title: 'Missions',
                },
                loadComponent: () =>
                  import(
                    '../components/loyalty-reward-page/missions/missions.component'
                  ).then((m) => m.MissionsComponent),
              },
            ],
          },
          {
            path: 'claim-bonus',
            data: {
              title: 'Claim Bonus',
            },
            loadComponent: () =>
              import(
                '../pages/account-center/claim-bonus/claim-bonus.component'
              ).then((m) => m.ClaimBonusComponent),
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'commission',
              },
              {
                path: 'commission',
                loadComponent: () =>
                  import(
                    '../pages/account-center/claim-bonus/commission/commission.component'
                  ).then((m) => m.CommissionComponent),
              },
              {
                path: 'cashback',
                loadComponent: () =>
                  import(
                    '../pages/account-center/claim-bonus/cashback/cashback.component'
                  ).then((m) => m.CashbackComponent),
              },
              {
                path: 'claims-history',
                loadComponent: () =>
                  import(
                    '../pages/account-center/claim-bonus/claims-history/claims-history.component'
                  ).then((m) => m.ClaimsHistoryComponent),
              },
              {
                path: 'combine-promo',
                loadComponent: () =>
                  import(
                    '../pages/account-center/claim-bonus/combine-promo/combine-promo.component'
                  ).then((m) => m.CombinePromoComponent),
              },
            ],
          },
          {
            path: 'bet-report',
            loadComponent: () =>
              import(
                '../pages/account-center/bet-report/bet-report.component'
              ).then((m) => m.BetReportComponent),
          },
          {
            path: 'message',
            loadComponent: () =>
              import('../pages/account-center/message/message.component').then(
                (m) => m.MessageComponent
              ),
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'inbox',
              },
              {
                path: 'inbox',
                loadComponent: () =>
                  import(
                    '../pages/account-center/message/inbox/inbox.component'
                  ).then((m) => m.InboxComponent),
              },
              {
                path: 'announcement',
                loadComponent: () =>
                  import(
                    '../pages/account-center/message/announcement/announcement.component'
                  ).then((m) => m.AnnouncementComponent),
              },
            ],
          },
          {
            path: 'message/new-message',
            loadComponent: () =>
              import(
                '../pages/account-center/message/new-message/new-message.component'
              ).then((m) => m.NewMessageComponent),
          },
        ],
      },
      // {
      //   path: 'slots',
      //   loadComponent: () =>
      //     import(
      //       '../components/provider-carousel/provider-carousel.component'
      //     ).then((m) => m.SlotsComponent),
      // },
      {
        path: ':providers',
        loadComponent: () =>
          import('../pages/providers/providers.component').then(
            (m) => m.ProvidersComponent
          ),
      },
      {
        path: ':footerStaticPage',
        loadComponent: () =>
          import(
            '../components/home-page/footer-static/footer-static.component'
          ).then((m) => m.FooterStaticComponent),
      },
      {
        path: ':providers/:providerCode',
        loadComponent: () =>
          import('../pages/provider/provider.component').then(
            (m) => m.ProviderComponent
          ),
      },
    ],
  },
  // {
  //   path: '**',
  //   redirectTo: '',
  // },
];
