<div
  class="hidden 1220:grid 1220:grid-cols-3 grid-cols-1 max-w-[1200px] mx-auto pb-8"
>
  <div class="footer-info">
    <div class="header">
      <div class="w-[40px] h-[40px]">
        <core-icon
          path="/icons/call-service.svg"
          alt="call-service"
          class="bg-primary"
        />
      </div>
      <div class="flex flex-col">
        <div class="text-[21px] uppercase">
          {{ "SERVICE" | translate }}
        </div>
        <div class="text-xs">
          {{ "SERVICE_ADVANTAGE" | translate }}
        </div>
      </div>
    </div>
    <div class="flex justify-between gap-3 relative">
      <div class="flex flex-col w-full">
        <h4>Deposit</h4>
        <p>Avarage time</p>
        <div class="w-full rounded-full h-2.5">
          <div class="bg-primary h-2.5 rounded-full" style="width: 15%"></div>
        </div>
      </div>
      <div class="absolute right-0 top-[10px] text-[44px] translate-y-[-20%]">
        <span>3</span>
        <span class="text-normal">min</span>
      </div>
    </div>
    <div class="flex justify-between gap-3 relative my-5 md:mb-5">
      <div class="flex flex-col w-full">
        <h4>Withdrawal</h4>
        <p>The Avarage Arrival Time</p>
        <div class="w-full bg-[#0a0909] rounded-full h-2.5">
          <div class="bg-primary h-2.5 rounded-full" style="width: 30%"></div>
        </div>
        <div class="progress"></div>
      </div>
      <div
        class="absolute right-0 top-[10px] text-[#737373] text-[44px] translate-y-[-20%]"
      >
        <span>5</span>
        <span class="text-normal">min</span>
      </div>
    </div>
  </div>
  <div class="footer-info">
    <div class="header">
      <div class="w-[40px] h-[40px]">
        <core-icon path="/icons/coin.svg" alt="coin" class="bg-primary" />
      </div>
      <div class="flex flex-col">
        <div class="text-[21px] uppercase">Products</div>
        <div class="text-xs">Product Advantages</div>
      </div>
    </div>
    <div class="flex flex-col">
      <div
        class="flex flex-col pr-2 pb-5 border-b border-dotted border-b-[#3f3d41]"
      >
        <h4>SLOTS BETTING</h4>
        <p>
          Online slots provider with variety selection of interesting games that
          make easy for players to hit the jackpot.
        </p>
      </div>
      <div
        class="flex flex-col pr-2 pb-5 pt-2.5 border-b border-dotted border-b-[#3f3d41]"
      >
        <h4>LOTTERY BETTING</h4>
        <p>
          An attractive lottery platform from the best companies in the world
          with the big winning prizes offers.
        </p>
      </div>
      <div
        class="flex flex-col pr-2 pb-5 pt-2.5 border-b border-dotted border-b-[#3f3d41]"
      >
        <h4>LIVE CASINO BETTING</h4>
        <p>
          The Platform of Choice for the World’s Top Companies, with the most
          games and variety..
        </p>
      </div>
      <div class="flex flex-col pr-2 pb-5 pt-2.5">
        <h4>SPORT BETTING</h4>
        <p>
          The Best Sportsbook Gaming Platform that offers more games, higher
          odds and provides more choices for players.
        </p>
      </div>
    </div>
  </div>
  <div class="footer-info">
    <div class="header">
      <div class="w-[40px] h-[40px]">
        <core-icon path="/icons/detail.svg" alt="detail" class="bg-primary" />
      </div>

      <div class="flex flex-col">
        <div class="text-[21px] uppercase">HELP & SUPPORT</div>
        <div class="text-xs">More Service</div>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-col gap-4 pr-2">
        <div class="ml-auto w-1/2">
          <a
            href="https://t.me/OfficialOvo"
            target="_blank"
            rel="noopener nofollow"
            class="bg-[#0a0a0a] flex items-center rounded-[30px] justify-between flex-row-reverse text-[13px]"
          >
            <i
              class="w-[36px] h-[36px] inline-flex items-center justify-center bg-primary rounded-[50%] mr-2.5"
            >
              <img
                alt="Contact"
                height="20"
                src="https://d2rzzcn1jnr24x.cloudfront.net/Images/communications/telegram.svg?v=20241125"
                width="20"
                class=""
              />
            </i>
            OfficialOvo
          </a>
        </div>
        <div class="ml-auto w-1/2">
          <a
            href="https://api.whatsapp.com/send?phone=6281327267080"
            target="_blank"
            rel="noopener nofollow"
            class="bg-[#0a0a0a] flex items-center rounded-[30px] justify-between flex-row-reverse text-[13px]"
          >
            <i
              class="w-[36px] h-[36px] inline-flex items-center justify-center bg-primary rounded-[50%] mr-2.5"
            >
              <img
                alt="Contact"
                height="20"
                loading="lazy"
                src="https://d2rzzcn1jnr24x.cloudfront.net/Images/communications/whatsapp.svg?v=20241125"
                width="20"
              />
            </i>
            +62 813-2726-7080
          </a>
        </div>
      </div>
      <div
        class="flex flex-col pt-2.5 my-5 border-y border-dotted border-y-[#3f3d41] 1220:border-b-0 pb-6"
      >
        <h4>CONNECT WITH US</h4>
      </div>
    </div>
  </div>
</div>
