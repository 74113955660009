<div class="flex flex-col items-center space-y-4">
  <!-- First Div -->
  <div class="flex flex-col items-center">
    <img [src]="popup().bannerWebpFilePath" alt="banner-popup" class="mb-2" />
    <h2 class="text-xl font-bold text-primary">Special Event Slot</h2>
  </div>

  <!-- Second Div -->
  <div class="flex items-center">
    <img [src]="popup().bannerFilePath" alt="Image 2" />
  </div>

  <!-- Third Div -->
  <div class="flex flex-col items-center">
    <h2 class="text-xl font-semibold mb-2">Title 2</h2>
    <div [innerHTML]="popup().description" class="text-center"></div>
  </div>
  <hr class="border-t border-dotted w-full my-4" />

  <!-- Fourth Div with Button -->
  <div class="flex justify-center w-full">
    <core-button (click)="close()">OK</core-button>
  </div>
</div>
